import React, { Component } from 'react';
import { Form, Text } from 'informed';
import './login.sass';
import axios from 'axios';

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loginError: false,
      loading: false
    }
  }

  render() {
    let loginButton;
    if (this.state.loading) {
      loginButton = <button type="submit" disabled >Signing In...</button>
    }
    else {
      loginButton = <button type="submit">Sign In</button>
    }
    return (
      <div id="login">
        {this.state.loginError &&
          <div className="error">
            {this.state.loginError}
          </div>
        }
        <Form id="login_form" onSubmit={this.logIn}>
          <div className="form-row">
            <label htmlFor="username">Username</label>
            <Text
              field="username"
              id="username"
              autocapitalize="none"
              autocorrect="off"
            />
          </div>
          <div className="form-row">
            <label htmlFor="password">Password</label>
            <Text
              type="password"
              field="password"
              id="password"
            />
          </div>
          {loginButton}
        </Form>
      </div >
    );
  }

  logIn = (formValues) => {
    this.setState({ loading: true });
    axios.get(
      `${process.env.REACT_APP_PROXY_URL}https://app.precioustatus.com/api/v1/loved_one`,
      {
        auth: {
          username: formValues.username,
          password: formValues.password
        },
        responseType: 'json',
        headers: {
          "Accept": "application/json"
        }
      }
    ).then((response) => {
      this.setState({ loading: false });
      const responseData = response.data;
      if (responseData.data && responseData.data.authentication_token) {
        this.props.onAuthTokenUpdate(responseData.data.authentication_token)
      } else {
        this.setState({ loginError: "Got unexpected data from the server" })
      }
    }).catch((error => {
      this.setState({ loading: false });
      if (error.response && error.response.status === 404) {
        this.setState({ loginError: "Login or Password Invalid" })
      } else {
        this.setState({ loginError: "An unknown error ocurred while logging in" })
      }
      console.log(error)
    }));
  }
}

export default Login;
