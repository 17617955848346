import React, { Component } from 'react';
import axios from 'axios';
import ReactPaginate from 'react-paginate';

import photoStore from './photoStore';

import './show.sass';

class Resident extends Component {
    constructor(props) {
        super(props)
        const { resident } = this.props;
        this.photoStore = new photoStore(resident.id);
        this.fullSyncPerformed = JSON.parse(localStorage.getItem(`fullSyncPerformed[${resident.id}]`)) || false;
        this.state = {
            photos: [],
            currentPagePhotos: [],
            currentPage: 1,
            photosPerPage: 50
        }
        this.photosRef = React.createRef()
    }
    componentDidMount() {
        this.updatePhotoStateFromStore();
        this.syncPhotos();
    }

    syncPhotos = () => {
        this.getUpdatePage(1).then((response) => {
            const maxPage = this.getTotalPagesFromResponse(response);
            this.photoStore.addPhotos(this.extractPhotosFromUpdates(response));

            this.updatePhotoStateFromStore();

            this.getBatch(2, maxPage);
        })
    }

    updatePhotoStateFromStore = () => {
        this.photoStore.getPhotos().then((photos) => {
            this.setState({
                photos: photos
            });
            this.goToPage(this.state.currentPage);
        });
    }

    goToPage = (page = 1) => {
        const { photosPerPage, photos } = this.state;
        const photoStart = (page - 1) * photosPerPage;
        const photoEnd = photoStart + photosPerPage;

        this.setState({
            currentPage: page,
            currentPagePhotos: photos.slice(photoStart, photoEnd)
        });
    }

    getBatch = (startPage, maxPage) => {
        let batch = [];
        let lastPage = Math.min(maxPage, startPage + 4)

        for (let currentPage = startPage; currentPage <= lastPage; currentPage++) {
            batch.push(
                this.getUpdatePage(currentPage)
                    .then(response => this.photoStore.addPhotos(this.extractPhotosFromUpdates(response)))
            );
        }
        Promise.all(batch).then((results) => {
            this.updatePhotoStateFromStore();
            const gotDuplicates = results.some(i => i.gotDuplicates === true)

            if ((!this.fullSyncPerformed && lastPage < maxPage) || (this.fullSyncPerformed && !gotDuplicates)) {
                return this.getBatch(lastPage + 1, maxPage);
            }
            if (lastPage === maxPage) {
                this.fullSyncPerformed = true;
                localStorage.setItem(`fullSyncPerformed[${this.props.resident.id}]`, true);
            }

        })
    }

    getUpdatePage = (page = 1) => {
        const { resident } = this.props
        return axios.get(
            `${process.env.REACT_APP_PROXY_URL}https://app.precioustatus.com/api/v1/residents/${resident.id}/updates?page=${page}`,
            {
                auth: {
                    username: this.props.authToken,
                    password: ''
                },
                responseType: 'json',
                headers: {
                    "Accept": "application/json"
                }
            }
        )
    }

    getTotalPagesFromResponse = (response) => {
        if (response.data) {
            return response.data.metadata.total_pages;
        }
        return undefined;
    }

    extractPhotosFromUpdates = (response) => {
        const responseData = response.data;

        if (responseData.data) {
            const newPhotos = responseData.data.filter((item) => {
                return item.photo && item.photo.original_url
            })
            return newPhotos;

        }

        console.error(responseData)
        return [];
    }

    renderPhoto = (entry) => {
        return (
            <li key={entry.id}>
                <img src={entry.photo.large_url} alt={entry.description} />
                <p>{entry.description}</p>
            </li>
        )
    }

    renderPhotos = () => {
        return (
            <ul className="photos">
                {this.state.currentPagePhotos.map(item => this.renderPhoto(item))}
            </ul>
        )
    }

    render() {
        if (this.props.selected) {
            return (
                <div className='resident'>
                    <div ref={this.photosRef}>
                        <div>{this.renderPagination()}</div>
                        <div>
                            {this.renderPhotos()}
                        </div>
                        <div>{this.renderPagination()}</div>
                    </div>
                </div>
            )
        }
        return null;
    }

    onPageChange = (pageObj) => {
        this.goToPage(pageObj.selected + 1);
        window.scrollTo(0, this.photosRef.current.offsetTop)
    }

    renderPagination = () => {
        const pageCount = Math.ceil(this.state.photos.length / this.state.photosPerPage);

        return (<ReactPaginate
            onPageChange={this.onPageChange}
            pageCount={pageCount}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            forcePage={this.state.currentPage - 1}
            containerClassName="pagination"
            activeClassName="active"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            pageClassName="page-item"
            previousClassName="page-item"
            nextClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
        />)
    }
}

export default Resident
