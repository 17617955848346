import React, { Component } from 'react';
import axios from 'axios';

import './index.sass';
import Resident from './show.js'

class Residents extends Component {
    constructor(props) {
        super(props)
        this.state = { residents: [], selected: null }
    }

    componentDidMount() {
        axios.get(
            `${process.env.REACT_APP_PROXY_URL}https://app.precioustatus.com/api/v1/residents`,
            {
                auth: {
                    username: this.props.authToken,
                    password: ''
                },
                responseType: 'json',
                headers: {
                    "Accept": "application/json"
                }
            }
        ).then((response) => {
            const responseData = response.data;

            if (responseData.data) {
                this.setState({
                    residents: responseData.data,
                    selected: responseData.data[0].id
                })
            } else {
                console.log(responseData)
            }
        })
    }

    selectResident = (id) => {
        this.setState({ selected: id })
    }

    render() {
        return (
            <div id="residents">
                <ul id='resident-selector'>
                    {this.state.residents.map(resident => {
                        const selected = this.state.selected === resident.id;
                        const className = selected ? "selected" : ""
                        return (
                            <li key={resident.id} className={className} onClick={() => this.selectResident(resident.id)}>
                                <img src={resident.profile_photo.thumbnail_url} alt="profile" />
                                <h2>{resident.first_name}</h2>
                            </li>
                        )
                    })}
                </ul>
                {this.state.residents.map(resident => {
                    const selected = this.state.selected === resident.id;
                    return <Resident key={resident.id} resident={resident} selected={selected} authToken={this.props.authToken} />
                })}
            </div>
        )
    }
}

export default Residents