class photoStore {
    constructor(residentId) {
        this.residentId = residentId;
        this.photoMap = new Map();
        this.orderedPhotos = [];
        this.getDb = new Promise((resolve, reject) => {
            const request = indexedDB.open(this.residentId, 1);
            request.onupgradeneeded = (event) => {
                const db = event.target.result;
                const store = db.createObjectStore('photos', { keyPath: 'id' });
                store.createIndex('occurred_at', 'occurred_at');
            }
            request.onsuccess = (event) => {
                this.db = event.target.result;
                resolve(this.db)
            };
            request.onerror = reject;
        });
    }

    addPhotos = (newPhotos) => {
        return new Promise((resolve, reject) => {
            this.getDb.then((db) => {
                const transaction = db.transaction(["photos"], "readwrite");
                const photoStore = transaction.objectStore("photos");

                const photosPromises = newPhotos.map(photo => {
                    return new Promise((photoResolve, photoReject) => {
                        const countRequest = photoStore.count(photo.id);
                        countRequest.onsuccess = (event) => {
                            const count = event.target.result;
                            if (count === 1) {
                                photoResolve(true)
                            } else {
                                photoStore.add(photo)
                                photoResolve(false)
                            }
                        }
                    });
                });
                Promise.all(photosPromises).then((values) => {
                    resolve({ gotDuplicates: values.some(x => x === true) });
                });
            });
        });
    }

    getPhotos = () => {
        return new Promise((resolve, reject) => {
            this.getDb.then((db) => {
                let photos = []
                const request = db.transaction("photos").objectStore("photos").index("occurred_at").openCursor(null, "prev");
                request.onsuccess = (event) => {
                    var cursor = event.target.result;
                    if (cursor) {
                        photos.push(cursor.value)
                        cursor.continue();
                    } else {
                        resolve(photos)
                    }
                }
            })
        });
    }
}

export default photoStore;