import React, { Component } from 'react';
import './App.sass';
import Login from './login/login.js';
import Residents from './residents';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { authToken: sessionStorage.getItem('authToken') };
  }

  render() {
    let body;
    if (this.state.authToken) {
      body = <Residents authToken={this.state.authToken} />
    } else {
      body = <Login onAuthTokenUpdate={this.onAuthTokenUpdate} />
    }
    return (
      <div className="App">
        <h1>Precious Photo</h1>
        {body}
      </div>
    );
  }

  onAuthTokenUpdate = (authToken) => {
    this.setState({ authToken })
    sessionStorage.setItem('authToken', authToken)
  }
}

export default App;
